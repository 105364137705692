@-webkit-keyframes roll-in-left {
    0% {
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateX(5%);
        transform: translateX(5%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-left {
    0% {
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateX(10%);
        transform: translateX(10%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-from-bottom {
    0% {
        -webkit-transform: translateY(100rem);
        transform: translateY(100rem);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateY(-3rem);
        transform: translateY(-3rem);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes roll-in-from-bottom {
    0% {
        -webkit-transform: translateY(10rem);
        transform: translateY(10rem);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateY(-1rem);
        transform: translateY(-1rem);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-from-right {
    0% {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateX(-10%);
        transform: translateX(-10%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes roll-in-from-right {
    0% {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
        opacity: 0;
    }
    90% {
        -webkit-transform: translateX(-10%);
        transform: translateX(-10%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-from-bottom-smooth {
    0% {
        -webkit-transform: translateY(2rem);
        transform: translateY(2rem);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}

@-webkit-keyframes roll-in-from-bottom-smooth {
    0% {
        -webkit-transform: translateY(2rem);
        transform: translateY(2rem);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
}