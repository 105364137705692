@mixin signupstep1 {
    @include large-devices{
        padding: 3% 10% 3% 10%;
    }
    @include mobile-tablet{
        padding: 5% 10% 5% 10%;
    }
    &--createFreeAccountText {
        @include mobile-tablet {
            font-size: 20px;
        }
        @include large-devices {
            font-size: 24px;
        }
        color: #111111;
        font-family: "Museo Sans";
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        font-weight: 800;
        height: 2rem;
    }
    &--organisationTypeLabelText {
        line-height: 24px;
        margin-right: auto;
        font-size: 12px;
        font-family: "Museo Sans";
        font-weight: 700
    }
    &--organisationTypeRadioGroup {
        line-height: 24px;
        margin-right: auto;
    }
    &--alreadyHaveAccount {
        color: #111111;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        margin-top: 16px;
        a {
            text-decoration: underline;
            font-size: 13px;
            font-weight: 700;
            margin-left: 5px;
        }
    }

    .ant-radio-wrapper {
        margin-right: 2px;
    }
    .phone-number-input{
        -moz-appearance:textfield !important;
        padding:0
    }


.ant-divider-horizontal {
    margin: 16px 0px 16px 0px;
}

}
