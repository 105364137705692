$breakpoint-mobile-start: 320px;
$breakpoint-mobile-end: 480px;
$breakpoint-tablet-start: 481px;
$breakpoint-tablet-end: 768px;
$breakpoint-ss-laptop-start: 769px;
$breakpoint-ss-laptop-end: 1024px;
$breakpoint-ls-laptop-start: 769px;
$breakpoint-ls-laptop-end: 1024px;
$breakpoint-el-laptop-start: 1025px;
$breakpoint-el-laptop-end: 1200px;
$breakpoint-small-phone-end: 385px;
@mixin mobile {
    @media (max-width: #{$breakpoint-mobile-end}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$breakpoint-tablet-end}) {
        @content;
    }
}

@mixin large-devices {
    @media (min-width: #{$breakpoint-ss-laptop-start}) {
        @content;
    }
}

@mixin mobile-tablet {
    @media (max-width: #{$breakpoint-tablet-end}) {
        @content;
    }
}

@mixin ss-laptop {
    @media (max-width: #{$breakpoint-ss-laptop-end}) {
        @content;
    }
}

@mixin ls-laptop {
    @media (max-width: #{$breakpoint-ls-laptop-end}) {
        @content;
    }
}

@mixin el-laptop {
    @media (max-width: #{$breakpoint-el-laptop-end}) {
        @content;
    }
}

@mixin small-phones {
    @media (max-width: #{$breakpoint-small-phone-end}) {
        @content;
    }
}