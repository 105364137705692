html {
    --antd-wave-shadow-color: white;
    &::selection {
        background: white;
    }
    font-size: 100%;
}

body {
    font-size: 14px;
}

@include large-devices{
.v2container {
    margin: 0;
    box-sizing: inherit;
    background-color: $container-backgroud-color !important;
    padding: 25px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-image: $gardient-color-image;
    background-size: cover;
    background-position: top;
    height: fit-content;
    min-height: calc(100vh - 60px);

    .content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        overflow-x: hidden;
        overflow-y: hidden;
        &:hover {
            overflow-y: scroll;
        }
        &::-webkit-scrollbar {
            width: 0;
            /* Remove scrollbar space */
            background: transparent;
        }
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
    .otherdetails-container {
        overflow-x: hidden;
        overflow-y: hidden;
        height: fit-content;
        margin-top: 2rem;
        &:hover {
            overflow-y: scroll;
        }
        &::-webkit-scrollbar {
            width: 0;
            /* Remove scrollbar space */
            background: transparent;
        }
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
    }
}
}

@include mobile-tablet {
    .v2container {
        margin: 0;
        box-sizing: inherit;
        background-color: $container-backgroud-color !important;
        padding: 25px;
        overflow-y: hidden;
        overflow-x: hidden;
        background-image: $gardient-color-image;
        background-size: cover;
        background-position: top;
        height: fit-content;
        min-height: calc(100vh - 60px);
    
        .content-container {
            overflow-x: hidden;
            overflow-y: hidden;
            &:hover {
                overflow-y: scroll;
            }
            &::-webkit-scrollbar {
                width: 0;
                /* Remove scrollbar space */
                background: transparent;
            }
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}

.anticon .anticon-loading .anticon-spin {
    svg {
        path {
            color: red;
        }
    }
}

@font-face {
    font-family: 'Museo Sans';
    src: local('Museo Sans'), url(../../assets//fonts/Museo_Sans_700.otf) format('opentype');
}