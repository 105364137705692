@mixin resetpassword {
    padding: 5% 15% 15% 15%;
    &--resetPasswordLabel {
        height: 16px;
        width: 173px;
        color: #111111;
        font-family: "Museo Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 700;
        width: 100%;
        margin-bottom: 50px;
    }
}