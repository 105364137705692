@mixin ant-select {
    .ant-select {
        &-selector {
            border: none;
            box-shadow: none;
        }
        &-focused {
            border: none;
        }
    }
}

.phone-number {
    .phone-number-input {
        @include mobile-tablet{
            font-size: 14px;
        }
        font-size: 1rem;
        font-family: Lato;
        outline: none;
        color: black;
        transition: 0.1s ease-out;
        width: 100%;
        &::-webkit-input-placeholder {
            opacity: 1;
        }
    }
    .ant-input {
            border: none;
            &:focus{
                border: none;
                box-shadow: none;
            }
    }
    .ant-form-item-explain{
        position: absolute;
        top: 120%;
        left: -40%
    }   
}

.checkbox-radio-label {
    font-size: 12px;
    color: black;
    font-family: Lato;
}

.checkbox-option-label {
    @include mobile-tablet {
        font-size: 14px;
    }
    @include small-phones {
        font-size: 12px;
    }
    font-size: 16px;
    font-family: Lato;
    color: #595959
}

.material-textfield {
    position: relative;
    width:100%;
    margin-bottom: 24px;
    .material {
        &-label {
            @include mobile-tablet{
                font-size: 12px;
            }
            position: absolute;
            font-size: 1rem;
            top: 50%;
            font-family: Lato;
            background-color: white;
            color: black;
            padding: 0 0.3rem;
            margin: 0 0.5rem;
            transition: .1s ease-out;
            transform-origin: left top;
            pointer-events: none;
            top: 0;
            transform: translateY(-50%) scale(.7);
        }
        &-input {
            @include mobile-tablet{
                font-size: 14px;
            }
            &::-webkit-input-placeholder {
                opacity: 1;
            }
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }
            font-size: 1rem;
            font-family: Lato;
            outline: none;
            border: 1px solid black;
            border-radius: 5px;
            padding: 0.4rem 0.8rem;
            color: black;
            transition: 0.1s ease-out;
            width: 100%;
            &__touched,
            &__dirty {
                border: 2px solid green;
            }
            &__error {
                border: 2px solid red;
            }
            .ant-form-item-has-error {
                font-size: 10px;
            }
        }
        &-select {
            @include ant-select();
            @include mobile-tablet{
                font-size: 14px;
            }
            &::-webkit-input-placeholder {
                opacity: 0.3;
            }
            padding: 0.3rem;
            font-size: 1rem;
            outline: none;
            border: 1px solid black;
            border-radius: 5px;
            color: black;
            transition: 0.1s ease-out;
            width: 100%;
            &__touched,
            &__dirty {
                border: 2px solid green;
            }
            &__error {
                border: 2px solid red;
            }
        }
        &-rightLabel {
            position: absolute;
            right: 24px;
            text-align: right;
            top:12px;
            height: 12px;
            color: #111111;
            font-family: Lato;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            z-index: 1;
            vertical-align: middle;
        }
        &-rightLabelLoader {
            position: absolute;
            right: 12px;
            text-align: right;
            top:8px;
            color: #361B6D;
            font-family: Lato;
            font-size: 14px;
            font-weight: 500;
            z-index: 1;
        }

        &-rightBottomLabel {
            position: absolute;
            text-align: right;
            transform: translate(-2%);
            color: #111111;
            font-family: Lato;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0;
            z-index: 1;
            vertical-align: middle;
            text-decoration: underline;
            right: 0px;
            margin-top: 5px;
            &:hover {
                cursor: pointer;
            }
            &__danger {
                color: red;
            }
        }
        &-leftBottomLabel {
            // position: absolute;
            text-align: right;
            transform: translate(-2%);
            color: #111111;
            font-family: Lato;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0;
            z-index: 1;
            vertical-align: middle;
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
            &__danger {
                color: red;
            }
        }
        &-errorLabel {
            position: relative;
            color: red;
            height: 12px;
            font-family: Lato;
            font-size: 10px;
            margin-top: 1px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 12px;
        }
        .select-selected:after {
            position: absolute;
            content: "";
            top: 14px;
            right: 10px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #fff transparent transparent transparent;
        }
    }
    .material-input:focus+.material-label {
        color: #6200EE;
    }
}

.step2-field {
    padding-left: 8%;
    padding-right: 8%;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input):hover .ant-select-selector {
    box-shadow: none;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
}

.ant-form-item {
    margin-bottom: 0px;
}
.ant-form-item-has-error {
    font-size: 10px;
}
.ant-form-item-explain {
    font-size: 10px;
    min-height: 0px;
}

@include mobile-tablet {
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
}