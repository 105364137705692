.others {
    display: flex;
    width: 100%;
    max-height: 700px;
    color: black;
    flex-direction: column;
    justify-content: flex-start;
    &__text1 {
        color: #FFFFFF;
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 36px;
        margin-bottom: 1rem;
        animation-name: roll-in-from-bottom-smooth;
        animation-delay: 0s;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: backwards;
    }
    &__text2 {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 2rem;
        opacity: 0.8;
        font-family: 'Open Sans';
        animation-name: roll-in-from-bottom-smooth;
        animation-delay: 0s;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: backwards;
    }
    &__benifits {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            animation-name: roll-in-from-bottom-smooth;
            animation-delay: 0s;
            animation-duration: 0.3s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: backwards;
            min-height: 160px;
            width: 27%;
            margin-bottom: 20px;
            &--image {
                max-height: 48px;
                max-width: 48px;
                margin-bottom: 5px;
                object-fit: cover;
            }
            &--name {
                color: #FFFFFF;
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 400;
                opacity: 0.7;                
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 12px;
            }
            &--percentage {
                width: 49px;
                color: #FFFFFF;
                font-size: 28px;
                font-weight: 700;
                opacity: 0.9;
                letter-spacing: 0;
                line-height: 38px;
                font-family: 'Open Sans';
                &--sign {
                    font-size: 14px;
                    font-weight: 600;
                    opacity: 0.9;
                    margin-left: 5px;
                }
            }
        }
    }
    &__bottomBar {
        width: 90%;
        height: 1px;
        background-color: #FFFFFF;
        animation-name: roll-in-from-bottom-smooth;
        animation-delay: 0s;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: backwards;
    }
}

@include ss-laptop {
    .others {
        padding-left: 5%;
        margin-top: 5%;
        &__text2 {
            min-height: 0;
            height: 24px;
        }
    }
}