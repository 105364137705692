@mixin forgotpassword {
    padding: 5% 15% 15% 15%;
    &--forgotpassword {
        height: 16px;
        width: 173px;
        color: #111111;
        font-family: "Museo Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 700;
        width: 100%;
    }
    &--forgotpasswordMessage {
        margin-top: 0.5rem;
        margin-bottom: 3rem;
        font-size: 14px;
        font-family: "Museo Sans";
        font-weight: 400;
    }
}