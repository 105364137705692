.faq {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    animation-name: roll-in-from-bottom-smooth;
    animation-delay: 0s;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;
    &__header {
        height: 34px;
        width: 79px;
        color: #FFFFFF;
        font-family: "Open Sans";
        font-size: 28px;
        font-weight: 700;
        opacity: 0.9;
        letter-spacing: 0;
        line-height: 34px;
        margin-bottom: 2rem;
    }
    &__plus {
        color: #FFFFFF;
        &:hover {
            cursor: pointer;
        }
    }
    &__question {
        min-height: 16px;
        width: 655px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        opacity: 0.9;
        letter-spacing: 0;
        line-height: 19px;
        font-family: 'Open Sans';
        margin-left: 1rem;
        &:hover {
            cursor: pointer;
        }
    }
    &__answer {
        min-height: 2rem;
        width: 655px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        opacity: 0.7;
        letter-spacing: 0;
        line-height: 22px;
        font-family: 'Open Sans';
        margin-left: 2.4em;
        margin-top: 1em;
        transition: ease-in-out;
        &:hover {
            cursor: pointer;
        }
    }
    &__dottedline {
        width: 100%;
        height: 2px;
        border-bottom: 2px dotted #FFFFFF;
    }
}

@include ss-laptop {
    .faq {
        margin-top: 25px;
        padding-left: 5%;
        margin-bottom: 25px;
        &__header {
            height: 34px;
            width: 100%;
        }
        &__question {
            min-height: 16px;
            width: 100%;
        }
        &__answer {
            min-height: 2rem;
            width: 100%;
            margin-left: 0;
            margin-top: 1em;
        }
        &__dottedline {
            width: 80%;
            height: 2px;
        }
    }
}