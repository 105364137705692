@mixin verifyemail {
    @include mobile-tablet{
        padding: 5% 10% 5% 10%;
    }
    &--emailNotVerified {
        color: #111111;
        font-family: "Museo Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 700;
        margin-bottom: 2rem;
        width: 100%;
    }
    &--sendEmailText {
        font-family: 'Lato', sans-serif;
        color: #333333;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
    &--notreceivedText {
        margin-top: 20px;
        color: #111111;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        a {
            text-decoration: underline;
            font-size: 13px;
            font-weight: 700;
            margin-left: 5px;
        }
    }
}