.signupcard {
    @include large-devices {
        min-width: 33rem;
        max-width: 70%;
    }

    @include mobile-tablet {
        .ant-card-body{
            padding: 0;
        }
    }
    animation-name: roll-in-from-right;
    animation-delay: 0s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;
    animation-iteration-count: 1;
    border-radius: 1rem;
    height: fit-content;
    
    .signupform {
        display: flex;
        align-items: center;
        .form-field {
            width: 100%;
            height: 3.5em;
            position: relative;
        }
        .form-field:first-child {
            margin-top: 10rem;
        }
        &__step1 {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @include signupstep1();
        }
        &__step2 {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @include signupstep2();
        }
        &__signin {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            @include signin();
        }
        &__verifyemail {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @include verifyemail();
        }
        &__forgotpassword {
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            @include forgotpassword();
        }
        &__resetpassword {
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            @include resetpassword();
        }
        &__signupinvite {
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            @include signupinvite();
        }
    }
}
