@include ss-laptop {
    nav {
        img {
            width: 140px;
            height: auto;
            margin-right: 12px;
        }
        button {
            width: auto;
        }
    }
}

.navbar-container {
    nav {
        width: 100%;
        height: 100px;
        background: $gradient-navbar;
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 2em 0 2em;
        overflow: hidden;
        img {
            height: 35px;
            object-fit: contain;
            display: block;
        }
        button {
            @include mobile-tablet {
                width: 96px;
            }
            margin-left: auto;
            width: 200px;
            height: 40px;
            background-color: $button-backgroud-color;
            border: 1px solid white;
            color: white;
            border-radius: 5px;
            text-transform: uppercase;
            &:hover {
                cursor: pointer;
            }
            &:active {
                transform: translateY(-0.1rem);
                box-shadow: 0 0.5rem 1rem black;
            }
            &::after {
                content: "";
                display: inline-block;
                height: 100%;
                width: 100%;
                border-radius: 10rem;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                transition: all 0.4s;
            }
        }

        .co-branded-logo-div {
            padding: 12px;
            margin-left: 32px;
            font-family: 'Open Sans';
            align-self: flex-start;
            .in-association-with-text{
                color:#FFF;
                font-size: 10px;
                font-weight: 400;
            };
            .logo-image {
                img {
                    max-height: 66px;
                    max-width: 140px;
                    height: auto;
                }
            }
        }

        @include mobile-tablet{
            .co-branded-logo-div {
                display: none;
            }
        }
    }
}