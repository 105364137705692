.btn {
    font-weight: 600;
    font-size: 12px;
    height: 36px;
    width: 100%;
    padding: 5px 14%;
    border-radius: 4px;
    box-shadow: 'none';
    &__google-btn {
        border: '1px solid #333333';
        color: '#111111';
    }
    &:focus {
        color: $container-backgroud-color;
    }
    &__get-started-btn {
        color: white;
        background-color: $button-backgroud-color;
        &:hover,
        &:active,
        &:focus {
            background-color: $button-backgroud-color;
            color: white;
        }
    }
}