@mixin signupinvite {
    &--pleasewait {
        color: #111111;
        font-family: "Museo Sans";
        font-size: 24px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        font-weight: 600;
    }
}