@mixin signin {
    @include mobile-tablet {
        padding: 5% 12% 15% 12%;
    }
    padding: 5% 15% 15% 15%;
    &--signinText {
        color: #111111;
        font-family: "Museo Sans";
        font-size: 24px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10%;
    }
    &--dontHaveAccount {
        margin-top: 10%;
        color: #111111;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        a {
            text-decoration: underline;
            font-size: 13px;
            font-weight: 700;
            margin-left: 5px;
        }
    }
    .btn__get-started-btn {
        margin-bottom: 10%;
        margin-top: 10%;
    }
}