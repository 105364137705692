@mixin signupstep2 {
    @include mobile-tablet{
        padding: 8% 3%;
    }
    &--niceToMeetText {
        @include mobile-tablet{
            font-size:16px;
        }
        color: #111111;
        font-family: "Museo Sans";
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 700;
        padding: 8% 3% 8% 3%;
        width: 100%;
        text-align: center ;
    }
    &--numberOfContainerLabel {
        height: 10px;
        color: #333333;
        font-family: Lato;
        font-size: 0.7rem;
        letter-spacing: 0;
        line-height: 10px;
        margin-bottom: 1.2rem;
        margin-right: -0.5rem;
    }
    &--rangeLabel {
        position: relative;
        left: 15%;
    }
    .input-range {
        width: 99%;
        &__track {
            &--active {
                background: #111111;
            }
        }
        &__slider {
            background: #6DC7E5;
            border: 1px solid #6DC7E5;
        }
        &__label {
            &--min,
            &--max,
            &-container {
                display: none;
            }
        }
    }
    .ant-checkbox-wrapper {
        margin-top: 1rem;
        height: 10px;
        width: 76px;
        color: #333333;
        font-family: Lato;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 10px;
        width: 100%;
    }
    .download-brochure {
        margin-top: 16px;
        height: 10px;
        width: 76px;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0;
        width: 100%;
        color: #333333;
        text-decoration: underline;
        color: #EF4277;
        text-align: center;
        &:hover {
            color: #571D73;
        }
    }
}